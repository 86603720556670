<template>
    <v-row justify="center">
        <v-card width="100%" color="transparent">
            <v-img :src="imageNote" height="100vh" max-height="100vh">
                <v-row class="fill-height" align="center">
                    <v-col class="text-center" cols="12" sm="12" md="6">
                        <v-avatar class="profile hidden-sm-and-down" size="50vh">
                            <v-img :src="myPict" contain></v-img>
                        </v-avatar>
                        <v-avatar class="profile hidden-md-and-up mb-5" size="30vh">
                            <v-img :src="myPict" contain :eager="true"></v-img>
                        </v-avatar>
                        <h1 class="text-md-h3 text-h4 mt-0 mb-0 white--text hidden-md-and-up">
                            Hi, I'm
                        </h1>
                        <span class="myName mobile hidden-md-and-up">Fakhrur Razi</span>
                        <vue-typed-js :strings="greeting" :showCursor="false" :typeSpeed="100">
                            <h1 class="white--text text-h6 hidden-md-and-up typing mobile"></h1>
                        </vue-typed-js>
                    </v-col>
                    <v-col class="text-left hidden-sm-and-down" cols="12" sm="12" md="6">
                        <h1 class="text-md-h3 text-h4 mt-0 mb-10 white--text">Hi, I'm</h1>
                        <span class="myName pl-15">Fakhrur Razi</span>
                        <vue-typed-js :strings="greeting" :showCursor="false" :typeSpeed="100">
                            <h1 class="white--text typing"></h1>
                        </vue-typed-js>
                    </v-col>
                </v-row>
                <div class="svg-border-waves">
                    <img src="@/assets/img/borderWaves.svg" />
                </div>
            </v-img>
            <v-card-text class="text-justify">
                <v-row align="center" class="mx-0" justify="center">
                    <v-col cols="12" sm="9">
                        <v-card v-scrollanimation color="info">
                            <v-card-title class="title white--text">
                                <h1 class="text-md-h4 text-h4 font-weight-bold title-card-about">
                                    Short Introduction
                                </h1>
                                <template v-if="roles == 'boss'">
                                    <v-btn
                                        color="green white--text"
                                        to="/edit/about/story"
                                        right
                                        fixed
                                    >
                                        <v-icon dark> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-img
                                :src="require(`@/assets/img/steps.png`)"
                                max-height="300px"
                                contain
                                style="
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    opacity: 0.5;
                                    z-index: 1;
                                "
                                class="hidden-sm-and-down"
                            >
                            </v-img>
                            <v-card-text class="white text--primary pt-5">
                                <VueShowdown
                                    :markdown="story"
                                    class="markdown-body"
                                    style="position: relative; z-index: 2"
                                />
                            </v-card-text>
                        </v-card>
                        <v-card v-scrollanimation color="info" class="mt-5">
                            <v-card-title class="title white--text">
                                <h1 class="text-md-h4 text-h4 font-weight-bold title-card-about">
                                    Projects and Experiences
                                </h1>
                                <template v-if="roles == 'boss'">
                                    <v-btn
                                        color="green white--text"
                                        to="/edit/about/project"
                                        right
                                        fixed
                                    >
                                        <v-icon dark> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                            </v-card-title>
                            <v-card-text class="white text--primary pt-5">
                                <v-img
                                    :src="require(`@/assets/img/projects.png`)"
                                    max-height="500px"
                                    position="right bottom"
                                    contain
                                    style="position: absolute; right: 0; opacity: 0.5; z-index: 1"
                                    class="hidden-sm-and-down"
                                >
                                </v-img>
                                <VueShowdown
                                    :markdown="project"
                                    class="markdown-body"
                                    style="position: relative; z-index: 2"
                                />
                            </v-card-text>
                        </v-card>
                        <v-timeline
                            align-top
                            :dense="$vuetify.breakpoint.smAndDown"
                            class="hidden-sm-and-down mt-5"
                        >
                            <v-timeline-item
                                v-for="achievement in achievements"
                                :key="achievement.Year"
                                :color="`info`"
                                small
                                fill-dot
                            >
                                <v-img :src="achievement.Img" slot="opposite"> </v-img>
                                <v-card :color="`info`" v-scrollanimation>
                                    <v-card-title class="title white--text">
                                        {{ achievement.Year }} Achievements
                                        <v-spacer></v-spacer>
                                        <template v-if="roles == 'boss'">
                                            <v-btn
                                                color="green white--text"
                                                :to="`/edit/achievement/${achievement.Year}`"
                                            >
                                                <v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                            <v-btn
                                                color="red white--text"
                                                @click="deleteAchievement(achievement.Year)"
                                                class="ml-5"
                                            >
                                                <v-icon dark> mdi-trash-can </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-card-title>
                                    <v-card-text class="white text--primary">
                                        <v-list dense>
                                            <v-list-item-group color="primary">
                                                <v-list-item
                                                    v-for="(item, i) in achievement.Items"
                                                    :key="i"
                                                    disabled
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon v-text="item.icon"></v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            class="black--text"
                                                            v-text="item.text"
                                                        ></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                        <v-card
                            :color="`info`"
                            v-scrollanimation
                            class="hidden-md-and-up mt-5"
                            v-for="achievement in achievements"
                            :key="achievement.Year"
                        >
                            <v-card-title class="title white--text">
                                {{ achievement.Year }} Achievements
                                <v-spacer></v-spacer>
                                <template v-if="roles == 'boss'">
                                    <v-btn
                                        color="green white--text"
                                        :to="`/edit/achievement/${achievement.Year}`"
                                    >
                                        <v-icon dark> mdi-pencil </v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="red white--text"
                                        @click="deleteAchievement(achievement.Year)"
                                        class="ml-1"
                                    >
                                        <v-icon dark> mdi-trash-can </v-icon>
                                    </v-btn>
                                </template>
                            </v-card-title>
                            <v-card-text class="white text--primary">
                                <v-list dense>
                                    <v-list-item-group color="primary">
                                        <v-list-item
                                            v-for="(item, i) in achievement.Items"
                                            :key="i"
                                            disabled
                                        >
                                            <v-list-item-icon>
                                                <v-icon v-text="item.icon"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    class="black--text"
                                                    v-text="item.text"
                                                ></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="justify-center mb-5">
                    <v-btn
                        color="red white--text"
                        @click="getAchievement()"
                        v-show="achievements.length < maxAchievement"
                        :loading="loading"
                    >
                        <v-icon>mdi-reload</v-icon> Load Old Achievements
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <Confirmation :dialog="dialog" />
    </v-row>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import "@/assets/css/markdown.css";
import axios from "axios";
import Confirmation from "@/components/Confirmation.vue";

export default {
    name: "About",
    components: {
        VueShowdown,
        Confirmation
    },
    props: ["roles"],
    data: () => ({
        count: 3,
        dialog: false,
        loading: false,
        toDelete: 0,
        story: "",
        project: "",
        imageNote: "",
        myPict: "",
        greeting: ["<strong>Developer | Pentester | CTF Player</strong>"],
        achievements: [],
        maxAchievement: 0
    }),
    methods: {
        getImageURL(img) {
            return "/assets/img/" + img;
        },
        async getAchievement() {
            this.loading = true;
            let resp = await axios.post(
                "/api/achievement",
                { start: this.achievements.length, count: this.count },
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
            resp.data.forEach((item) => {
                item.Img = this.getImageURL(item.Img);
                item.Items = JSON.parse(atob(item.Items));
                this.achievements.push(item);
            });
            this.loading = false;
        },
        async deleteAction(id) {
            this.$toast.info("Deleting Achievement...");
            try {
                let resp = await axios.delete("/api/achievement/" + id, {
                    withCredentials: true
                });
                if (resp.status == 204) {
                    this.$toast.success("Achievement Deleted", {
                        onClose: () => {
                            this.achievements = this.achievements.filter((v) => v.Year != id);
                            this.maxAchievement -= 1;
                        }
                    });
                } else {
                    this.$toast.error("Failed to Remove Achievement");
                }
            } catch (error) {
                this.$toast.error("Failed to Remove Achievement");
            }
        },
        deleteAchievement(id) {
            this.dialog = true;
            this.toDelete = id;
        }
    },
    async created() {
        let about = await axios.get("/api/about");
        this.story = about.data.Story;
        this.project = about.data.Project;
        this.imageNote = this.getImageURL(about.data.Img);
        this.myPict = this.getImageURL(about.data.Photos);
        this.greeting = [about.data.Greeting];
        let max = await axios.get("/api/count/achievements");
        this.maxAchievement = max.data.count;
        this.getAchievement();
    },
    mounted() {
        this.$root.$on("confirmed", (data) => {
            if (data) {
                this.deleteAction(this.toDelete);
                this.toDelete = "";
                this.dialog = false;
            } else {
                this.dialog = false;
            }
        });
    },
    beforeDestroy() {
        this.$root.$off("confirmed");
    }
};
</script>

<style scoped>
.row {
    margin-left: 0;
    margin-right: 0;
}
.mobileTyped {
    position: absolute;
    width: 100%;
    margin-left: -12px;
}
.before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s ease-out;
}
.enter {
    opacity: 1;
    transform: translateY(0px);
}
.myName {
    font-family: Aguafina Script;
    visibility: visible;
    text-align: inherit;
    line-height: 180px;
    letter-spacing: -11px;
    font-weight: 400;
    font-size: 180px;
    border-color: rgb(114, 114, 114);
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    color: black;
    text-decoration: none;
    white-space: nowrap;
    width: auto;
    height: auto;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    transform-origin: 50% 50% 0px;
}

.myName.mobile {
    letter-spacing: -5px;
    font-size: 90px;
}
.typing {
    margin-top: -50px;
    padding-left: 70px;
}
.typing.mobile {
    margin: auto;
    margin-top: -50px !important;
    padding-left: 0px;
}
.title-card-about {
    margin: auto;
    font-family: Aguafina Script !important;
    font-size: 40px !important;
}
.v-image > .v-responsive__content > .svg-border-waves > img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
}
</style>
